#adminPage {
  line-height: 30px;
}

#adminPage .Button {
  font-size: 8px;
  padding: 1px 3px;
}

#response {
  overflow-y: auto;
  height: 50vh;
  max-width: 90vw;
  word-wrap: break-word;
}