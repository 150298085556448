#loginPopup {
    position: fixed;
    background-color: rgb(29, 29, 29);
    margin-left: auto;
    margin-right: auto;
    width: 400px;
    right: 0;
    left: 0;
    top: 10vh;
    text-align: center;
    border: 2px solid #FF8CD7;
    z-index: 1;
    border-radius: 10px;
  }

  #loginText {
      font-size: 25px;
      line-height: 20px;
  }

  #blackout {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    z-index: 2;
    overflow: hidden;
    box-sizing: border-box;
    background-color: black;
    opacity: 0.5;
    z-index: 0;
  }

  #backButton {
    font-size: 10px;
    padding: 5px;
    margin-bottom: 2em;
  }