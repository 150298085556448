#fullscreenPreview {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    z-index: 4;
    overflow: hidden;
    box-sizing: border-box;
    background-color: rgb(29, 29, 29);
  }

  #previewControls {
    display: inline-flex;
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
  }
  
  .previewButton {
    flex-grow: 1;
    border: 1px solid white;
    opacity: 0.7;
    padding: 100px 10px;
  }
  
  #previewMain {
    display: inline-flex;
    flex-direction: column;
  }
  
  .previewMainSub {
    flex-grow: 1;
  }

  #fsPreviewImage {
    pointer-events: none;
  }