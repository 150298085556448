
#loadingPopup {
    position: absolute;
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    right: 0;
    left: 0;
    margin-top: -50px;
    text-align: center;
    border: 2px solid #FF8CD7;
    background-color: rgb(29, 29, 29);
    z-index: 10;
    border-radius: 10px;
}