table, th, td {
  color: white;
  border: 1px solid white;
  font-size: 30px;
}

table {
  margin: 20px auto;
}

th, td {
  max-width: 500px;
  white-space: nowrap;
  overflow: hidden;
  padding: 0 10px;
}

th {
  color:#FF8CD7;
}

a {
  color: #C8C800;
}

a:link {
  color: #C8C800;
}

a:visited {
  color: pink;
}

a:hover {
  color: #FF8CD7;
}

a:active {
  color: yellow;
}