#button-imagePreview {
    font-size: 20px;
    padding: 0 1vh;
    display: inline;
  }
  
  #button-getRandomImage {
    font-size: 20px;
    padding: 0 1vh;
    display: inline;
  }

  #videoSource {
    position: fixed;
    opacity: 1;
    top: 0;
    left: 0;
    z-index: -1;
    max-width: 100vw;
    max-height: 100vh;
  }
  
  #clickCount {
    position: fixed;
    bottom: -5px;
    right: 6px;
    font-size: 20px;
    margin: 0;
  }
  
  #previewImage {
    position: fixed;
    max-width: 400px;
    height: 200px;
    margin-left: auto;
    margin-right: auto;
    right: 0;
    left: 0;
    top: 3vh;
    text-align: center;
    border: 2px solid #FF8CD7;
    z-index: 1;
    border-radius: 10px;
  }

  #subReddit {
    width: 100px;
  }

  .row {
    display: flex;
    justify-content: center;
  }
  
  .tileContainer {
    overflow: hidden;
    box-sizing: border-box;
  }
  
  #secretButton {
    position: fixed;
    width: 20px;
    height: 20px;
    top: 15px;
    left: 13px;
  }

  .puzzleMain {
    display: inline-flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    flex: 1;
    box-sizing: border-box;
    border: 2px solid black;
    background-color: black;
  }