.button {
    font-size: 4vh;
    display: inline-block;
    color:#FF8CD7;
    margin: 1vh 1vw;
    padding: 0 3vh;
    background-color: #0A0A0A;
    border-radius: 10px;
    border: 2px solid #0A0A0A;
}

.buttonCont {
    position: absolute;
    margin: auto;
    display: flex;
    pointer-events: none;
}

.buttonCont .smallButton {
    position: relative;
    margin: 2px;
    pointer-events: auto;
}

.smallButton {
    position: absolute;
    margin: auto;
    color: #C8C800;
    background-color: #0A0A0A;
    border: solid #FF8CD7 2px;
    border-radius: 10px;
    font-size: 10px;
    padding: 5px;
    width: 25px;
    height: 25px;
}

.button .altColor{
    color:  #FF8CD7;
    border: solid #C8C800 2px;

}

.smallButton:hover {
    background-color: #381c2e;
    cursor: pointer;
    border: 2px solid #FF8CD7;
    opacity: 1 !important;
}