html, body, #root {
  margin: 0;
  padding: 0;
  height: 100%;
}

.App {
  text-align: center;
  font-family: 'Dongle', sans-serif;
  height:100%;
  overflow: hidden;
}

.App-logo {
  margin-top: 20px;
  height: 15vmin;
  min-height: 100px;
  pointer-events: none;
}

.App-main {
  background-color: rgb(29, 29, 29);
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(20px + 2vh);
  color: white;
}

.material-icons {
  vertical-align: middle;
  pointer-events: none;
}

#title {
  font-size: calc(50px + 2vh);
  line-height: calc(35px + 2vh);
  margin-top: 5px;
}

.Button {
  font-size: 4vh;
  display: inline-block;
  color:#FF8CD7;
  margin: 1vh 1vw;
  padding: 0 3vh;
  background-color: #0A0A0A;
  border-radius: 10px;
  border: 2px solid #0A0A0A;
}

.Button span {
  color: #C8C800;
}

.Button:hover {
  background-color: #381c2e;
  cursor: pointer;
  border: 2px solid #FF8CD7;
}

.buttonContainer {
  position: absolute;
  right: 0;
  top: 0;
  margin: 5px;
  z-index: 10;
}

.buttonContainer .Button {
  font-size:20px;
  padding: 0 5px;
  margin: 5px;
}

.form {
  display: inline-flex;
  flex-direction: column;
  align-content: center;
  border: 2px solid white;
  width: 75vw;
  max-width: 350px;
  border-radius: 10px;
  margin: calc(5px + 2vh) 10px;
  padding: 0 3vw 1vh;
  line-height: calc(10px + 2vh);
}

.formTitle {
  font-size: calc(25px + 2vh);
  position: relative;
  top: calc(-2vh + 2px);
  color:#FF8CD7;
  align-content: center;
  line-height: calc(15px + 2vh);
  overflow: hidden;
  margin-bottom: -1vh;
}

.formTitleSpan {
  padding: 0 10px;
  background-color: rgb(29, 29, 29);
}

.altColor{
  color: #C8C800;
  pointer-events: none;
}

input {
  margin-bottom: 2vh;
  width: 200px;
  align-self: center;
  text-align: center;
  border-radius: 5px;
}

input[type=text] {
  position: relative;
  top: 2px;
}