#imageGallery {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  z-index: 3;
  overflow: hidden;
  box-sizing: border-box;
  background-color: rgb(29, 29, 29);
}

ul {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: rgb(29, 29, 29);
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    overflow-x: hidden;
}

li {
  flex-grow: 1;
}

li:last-child {
  flex-grow: 10;
}

.galleryImage {
  max-height: 100%;
  width: 100%;
  max-width: 950px;
  object-fit: cover;
  vertical-align: bottom;
}